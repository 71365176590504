<template>
    <div class="curContainer" style="background: #fff;">
        <div style="min-height:400px;">

            <div>
                <div style="font-weight: bold;">渠道列表</div>
                <table style="margin-top:8px;width: 95%;" class="my-table">
                    <tr style="font-weight: bold;">
                        <td style='min-width:70px;text-align:center;'>序号</td>
                        <td style="padding:0 7px;min-width:100px;text-align:center;" >渠道</td>
                        <td style="padding:0 7px;min-width:260px;text-align:center;" >跳转链接</td>
                        <td style='min-width:70px;text-align:center;'>操作</td>
                    </tr>
                    <tr v-for="(itemrow,indexrow) in sList" :key="indexrow" style="    height: 2.2rem;">
                        <td style="min-width:70px;text-align:center;">{{ indexrow+1 }}</td>
                        <td style="min-width:100px;text-align:center;">{{ itemrow.name }}</td>
                        <td style="min-width:260px;text-align:center;">{{ itemrow.url }}</td>
                        <td style="min-width:70px;text-align:center;">
                            <!-- <i style="color:red;text-align: center;" class="el-icon-delete optBtn" @click="onItemDelete(index)"></i> -->
                            <el-tooltip class="item" effect="dark" content="正式问卷" placement="bottom">
                                <el-button type="primary" size="mini" icon="el-icon-video-play" @click="onOpenSurvey(indexrow)"></el-button>    
                            </el-tooltip>     
                            <el-tooltip class="item" effect="dark" content="试答问卷" placement="bottom">
                                <el-button type="danger" size="mini" icon="el-icon-smoking" @click="onTrySurvey(indexrow)"></el-button>    
                            </el-tooltip>     
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <el-button type="danger" size="mini" icon="el-icon-delete optBtn" @click="onItemDelete(indexrow)"></el-button>    
                            </el-tooltip>   
                        </td>
                    </tr>
                </table>
            </div>
            <el-divider style="margin-top:40px;" content-position="center">选择添加项</el-divider>
            <div>
                <div style="line-height: 2.3rem;width:100%;">    
                    <el-form inline :model="form" label-width="180px" style="margin-top: 30px;">
                        <el-form-item label="渠道名称">
                            <el-input v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="跳转链接">
                            <el-input v-model="form.url" autocomplete="off"></el-input>
                        </el-form-item>
                        <div style="margin-top:30px;margin-left: 30px;color:#82C0FF;cursor:pointer;text-decoration: underline;    text-align: right;" @click="onItemAdd">添加渠道信息</div>
                    </el-form>   
                </div>
            </div>
            <el-divider style="margin-top:40px;" content-position="center"></el-divider>
            <div style="text-align:center;margin-top:20px;">
                <el-button :disabled="sList.length === 0" @click="onSave" type="primary">保存</el-button>
                <el-button @click="onMainClose">关闭</el-button>
            </div>

        </div>
    </div>
</template>
<script>
// import { mapGetters } from "vuex"; 
// import {getToken} from "@/utils/auth";
import { encrypt } from '@/utils/encrypt'

export default {
    components: {
        
    },
    props: { 
        'shareList': {
            type:Array,
            default(){
                return []
            }
        },
        'projectItem':{
            type:Object
        }
        // 'questionList': {
        //     type:Array,
        //     default(){
        //         return []
        //     }
        // },
     },
    watch:{
        shareList:{
            handler(newval){
                this.sList = newval
                console.log('this.sList',this.sList)
            },
            deep:true,
            immediate:true
        },
        // questionList:{
        //     handler(newval){
        //         this.qaList = newval.filter(function (item) {
        //             return item.typeId !== 3 && item.typeId !== 6
        //         })
        //         // console.log('aa',newval)
        //     },
        //     deep:true,
        //     immediate:true
        // }
    },
    // computed: {...mapGetters(['projectQuotaList'])},
    data() {
        return {
            sList: [],
            // qaList: [],
            form: {id:0,name: '', url: ''},
            // answers: [],
            // queCols: [],
        };
    },
    async mounted() {
        
    },
    methods: {
        onItemAdd() {
            const {id,name, url} = this.form
            if (!name ){
                this.$message.error('请填写渠道名称');
                return
            }
            this.sList.push({id,name, url})
            this.form =  {id:0,name: '', url: ''}
        },
        onItemDelete(index) {
            this.sList.splice(index,1)
        },    
        onOpenSurvey(index){
            console.log('this.sList[index]',index,this.sList[index])
            if (this.sList[index].id === 0){
                this.$message.error('请先保存后再打开问卷');
                return
            }
            window.open(this.projectItem.path + 'survey/' + encrypt(this.projectItem.projectId) +'/'+this.projectItem.rxsign+ '/0/0/'+this.sList[index].id);
        }, 
        onTrySurvey(index){
            console.log('this.sList[index]',index,this.sList[index])
            if (this.sList[index].id === 0){
                this.$message.error('请先保存后再打开问卷');
                return
            }
            window.open(this.projectItem.path + 'survey/' + encrypt(this.projectItem.projectId) +'/tset'+this.projectItem.rxsign+ '/0/0/'+this.sList[index].id);
        }, 
        onSave(){
            this.$emit('saveagent',this.sList)
        },
        onMainClose(){
            this.$emit('saveagent',null)
        }
        
    }
}
</script>
 
<style  lang="scss">
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
}
.my-table td {
    border: 1px solid black;
}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #edf6ff;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
