<template>
    <div class="curContainer" style="background: #fff;">
        <div style="min-height:400px;">
            <!-- <div class="relaTable" style="display: flex;justify-content: space-between;" v-for="(item,index) in qtList" :key="index">
                {{ index + 1 }}:{{item.name}}                
                <div style="width:50px;display: flex;align-items: center;">                            
                    <i style="color:red;text-align: center;" class="el-icon-delete optBtn" @click="onRelationItemDelete(index)"></i>
                </div>
            </div> -->
            <div>
                <div style="font-weight: bold;">配额列表</div>
                <table style="margin-top:8px;" class="my-table">
                    <tr style="font-weight: bold;">
                        <td style='min-width:70px;text-align:center;'>序号</td>
                        <td style="padding:0 7px;min-width:400px;text-align:center;" >条目</td>
                        <td style="padding:0 7px;min-width:150px;text-align:center;" >额度</td>
                        <td style='min-width:70px;text-align:center;'>操作</td>
                    </tr>
                    <tr v-for="(itemrow,indexrow) in qtList" :key="indexrow">
                        <td style="min-width:70px;text-align:center;">{{ indexrow+1 }}</td>
                        <td style="min-width:400px;text-align:center;">{{ itemrow.name }}</td>
                        <td style="min-width:150px;text-align:center;">{{ itemrow.num }}</td>
                        <td style="min-width:70px;text-align:center;">
                            <i style="color:red;text-align: center;" class="el-icon-delete optBtn" @click="onRelationItemDelete(index)"></i>
                        </td>
                    </tr>
                </table>
            </div>
            <el-divider style="margin-top:40px;" content-position="center">选择添加项</el-divider>
            <div>
                <div style="line-height: 2.3rem;width:100%;">                    
                    <div>
                        <span style="font-weight: bold;">题目:</span>
                        <el-select style="margin-left:8px;" v-model="qac.q" 
                            placeholder="请选择"
                            @change="onRelationQChange"
                            >
                            <el-option v-for="q in qaList" :key="q.qID" :label="q.name" :value="q.qID"></el-option>
                        </el-select>
                    </div>
                    <div v-if="qac.q && answers.length > 0" style="display:flex;align-items: center;margin-top: 20px;">
                        <div style="font-weight: bold;margin-left:20px;    width: 160px;">答案:</div>
                        <div>
                            <el-radio-group v-model="qac.a">
                                <el-radio :label="item.aId" v-for="(item,index) in answers" :key="index">{{item.answer}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <div v-if="qac.a && queCols.length>0" style="display:flex;background-color: #fff;align-items: center;margin-top: 20px;">
                        <div style="font-weight: bold;margin-left:20px;width: 60px;">列:</div>
                        <div>
                            <el-radio-group v-model="qac.c">
                                <el-radio :label="item.colId" v-for="(item,index) in queCols" :key="index">{{item.colName}}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                    <!-- <div>数量</div> -->
                    <div style="display:flex;">
                        <el-form inline :model="editForm" label-width="140px" label-position="left" style="margin-top: 30px;">
                            <el-form-item style="font-weight: bold;" label="配额数量" required>
                                <el-input v-model="editForm.num" autocomplete="off" min="1" max="999999" placeholder="请输入配额数量"></el-input>
                            </el-form-item>
                        </el-form>
                        <div style="margin-top:30px;margin-left: 30px;color:#82C0FF;cursor:pointer;text-decoration: underline;" @click="onRelationItemAdd">添加至配额列表</div>
                    </div>
                </div>
            </div>
            <div style="text-align: left;margin-top:10px;">
                
            </div>
            <el-divider style="margin-top:40px;" content-position="center"></el-divider>
            <div style="text-align:center;margin-top:20px;">
                <el-button :disabled="qtList.length === 0" @click="onSave" type="primary">保存</el-button>
                <el-button @click="onMainClose">关闭</el-button>
            </div>

        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
import eachquestion from '@/views/Survey/bkground/component/eachQuestion';
import { DeleModel, SaveModel } from "@/api/survey";
import {getToken} from "@/utils/auth";


export default {
    components: {
        eachquestion
    },
    props: { 
        'quotaList': {
            type:Array,
            default(){
                return []
            }
        },
        'questionList': {
            type:Array,
            default(){
                return []
            }
        },
     },
    watch:{
        quotaList:{
            handler(newval){
                this.qtList = newval
            },
            deep:true,
            immediate:true
        },
        questionList:{
            handler(newval){
                this.qaList = newval.filter(function (item) {
                    return item.typeId !== 3 && item.typeId !== 6
                })
                // console.log('aa',newval)
            },
            deep:true,
            immediate:true
        }
    },
    computed: {...mapGetters(['projectQuotaList'])},
    data() {
        return {
            qtList: [],
            qaList: [],
            qac: {q: '', a: '', c: ''},
            answers: [],
            queCols: [],
            editForm: {
                num: ''
            }
        };
    },
    async mounted() {
        
    },
    methods: {
        onRelationItemAdd() {
            const {q, a, c} = this.qac
            if (!q || !a ){
                this.$message.error('请将选项选择完整');
                return
            }
            if(this.queCols && this.queCols.length>0 && !c){
                this.$message.error('请将选项选择完整');
                return
            }
            if(!this.editForm.num){
                this.$message.error('请输入配额数量');
                return
            }
            var value = q + '/' + a + '/' + c

            var findQ = this.qtList.filter(function (item) {
                    return item.value == value
                })
            if (findQ && findQ.length>0){
                this.$message.error('该项目已加入，不可重复添加');
                return
            }

            var name = ''
            console.log(this.qaList)
            name = this.qaList.filter(function (item) {
                    return item.qID*1 == q*1
                })[0].name
            name += '/' + this.answers.filter(function (item) {
                    return item.aId*1 == a*1
                })[0].answer
            if (c){
                name += '/' + this.queCols.filter(function (item) {
                        return item.colId*1 == c*1
                    })[0].colName
            }
            this.qtList.push({value, name, num: this.editForm.num})
        },
        onRelationItemDelete(index) {
            this.qtList.splice(index,1)
        },
        onRelationQChange(){
            // console.log('this.qac',this.qaList,this.qac.q)
            var vm = this
            var findQ = this.qaList.filter(function (item) {
                    return item.qID == vm.qac.q*1
                })
            
            this.answers = findQ[0].answers
            this.queCols = findQ[0].queCols
            this.qac.a = ''
            this.qac.c = ''
            // console.log('this.answers',this.answers)
            // console.log('this.queCols',this.queCols)
        },
        onSave(){
            this.$emit('savequota',this.qtList)
        },
        onMainClose(){
            this.$emit('savequota',null)
        }
        
    }
}
</script>
 
<style  lang="scss">
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
}
.my-table td {
    border: 1px solid black;
}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #edf6ff;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
