<template>
    <div class="curContainer" style="background: #fff;">
        <div style="min-height:400px;">
            <!-- <div>
                <div style="font-weight: bold;">四维动量系数</div>
            </div> -->
            <el-divider style="margin-top:40px;" content-position="center">四维/三力动量系数</el-divider>
            <div style="margin-left:20px;">
                <!-- <el-form inline :model="editForm" label-width="140px" label-position="left" style="margin-top: 30px;">
                    <div style="line-height: 2.3rem;width:100%;" v-for="(item,index) in rList" :key="index">                    
                        <el-form-item style="font-weight: bold;" :label="item.dimName" required>
                            <el-input v-model="item.ratio" autocomplete="off" min="0" max="99" placeholder="请输入系数"></el-input>
                        </el-form-item>
                    </div>
                </el-form> -->
                <common-table ref="filterTable" :data="rList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}" style="width: 100%;" stripe>
                    <el-table-column prop="dimName" label="指标" sortable width="130" >
                        <template v-slot="scope">
                            {{ scope.row.dimName.replace('行动力','预行动') }}
                        </template>
                    </el-table-column>
                    <el-table-column v-if="canQuery" prop="ratio" label="系数" sortable width="120">
                        <template v-slot="scope">
                            <el-input v-model="scope.row.ratio" autocomplete="off" min="0" max="99" placeholder="请输入系数"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column label="问题选项" sortable >
                        <template v-slot="scope">  
                            <div v-if="scope.row.answerItemArray?.length>0">
                                <div v-for="(item,index) in scope.row.answerItemArray">
                                    <div style="display:flex;    justify-content: space-between;">
                                        <div>{{ item }}</div>
                                        <div><el-button type="danger" size="mini" icon="el-icon-close" @click="onRemoveItem(scope.row.answerItemArray,index)"></el-button></div>
                                    </div>
                                </div>
                            </div>
                            <div style="text-align: right;"><el-button type="primary" size="mini" icon="el-icon-plus" @click="onOpenSelect(scope.row)"></el-button></div>
                            
                        </template>
                    </el-table-column>                    
                </common-table>
            </div>

            <!-- <el-divider style="margin-top:40px;" content-position="center"></el-divider> -->
            <div style="text-align:center;margin-top:20px;">
                <el-button @click="onSave" type="primary">保存</el-button>
                <el-button @click="onMainClose">关闭</el-button>
            </div>

        </div>

        <el-dialog title="选择问题选项" v-model="selectVisible" width="60%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="selectVisible"
                                class="dialog"
                                append-to-body>
            <div>     
                <div >
                    <el-checkbox-group v-model="eachHaveSelect">
                        <div><el-checkbox v-for="item in canSelect" :label="item" :key="item">{{item}}</el-checkbox></div>
                    </el-checkbox-group>
                </div>   
                
                <div style="display:flex;    justify-content: center;margin-top:1rem;">
                    <el-button type="primary" size="mini" :disabled="eachHaveSelect.length==0" @click="onSelectFinish">确定</el-button>
                    <el-button type="danger" size="mini" @click="selectVisible=false">返回</el-button>
                </div>
                <el-divider>说明</el-divider>
                <div style="margin-top:1rem;">
                    此处选项的答案均来自于以下问题的句式：<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;对于{*}的一些描述，请问您是否同意{*}<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;以下关于{*}的一些描述，请问您是否同意{*}<br>
                    <span style="color:red;">注：{*}代表任意内容</span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script>
import { mapGetters } from "vuex"; 
import {getToken} from "@/utils/auth";
import {GetSiweiSanliItem} from '@/api/survey'
import CommonTable from "@/components/CommonTable";

export default {
    components: {
        CommonTable
    },
    props: { 
        'ratioList': {
            type:Array,
            default(){
                return []
            }
        },    
        'canQuery':{
            type:Boolean,
            default(){
                return false
            }
        },
     },
    watch:{
        ratioList:{
            handler(newval){
                // this.rList = newval
                // this.rList.answerItemArray=[]
                // // console.log('newval',newval)
                // newval.forEach(ele => {
                //     if (ele.answerItem) {
                //         ele.answerItemArray = ele.answerItem.split('^')
                //     }
                // });
                
                
            },
            deep:true,
            immediate:true
        },
       
    },
    computed: {...mapGetters(['projectRatioList'])},
    data() {
        return {
            rList: [],
            selectVisible:false,
            curItem:{},
            answerList:[],
            canSelect:[],
            haveSelect:[],
            eachHaveSelect:[],
        };
    },
    async mounted() {
        
    },
    methods: {
        async init(){
            // console.log('init')
            this.rList = this.ratioList
            this.rList.forEach(ele => {
                if (ele.answerItem) {
                    ele.answerItemArray = ele.answerItem.split('^')
                }else{
                    ele.answerItemArray =[]
                }
            });

            await GetSiweiSanliItem({userId: getToken(),projectId:this.rList[0].projectId}).then((res)=>{
                this.answerList = res.data
            })
            
            this.getCanSelect()
            // console.log('this.rList',this.rList)
            // console.log('this.answerList',this.answerList)
            // console.log('this.haveSelect',this.haveSelect)
        },
        onRemoveItem(item,index){
            item.splice(index, 1);
        },
        getCanSelect(){
            this.haveSelect=[]
            this.rList.forEach(ele => {
                if (ele.answerItemArray && ele.answerItemArray.length>0){
                    this.haveSelect = this.haveSelect.concat(ele.answerItemArray)
                }
            });
            this.canSelect=[]
            this.answerList.forEach(ele => {
                let index = this.haveSelect.findIndex(val => val === ele.answer);
                if (index == -1){
                    this.canSelect.push(ele.answer)
                }               
            });
        },
        onSelectFinish(){
            // console.log(this.eachHaveSelect)
            this.eachHaveSelect.forEach(ele => {
                console.log(this.curItem)
                this.curItem.answerItemArray.push(ele)
            });
            // console.log(this.curItem.answerItemArray)
            this.eachHaveSelect=[]            
            this.selectVisible = false
        },
        onOpenSelect(item){
            this.curItem = item     
            this.getCanSelect();       
            this.selectVisible = true
        },
        onSave(){
            this.$emit('saveratio',this.rList)
        },
        onMainClose(){
            this.$emit('saveratio',null)
        }
        
    }
}
</script>
 
<style  lang="scss">
.el-dropdown-menu{
    display:none
}
.dropright:hover > .dropdown-menu {
    display:block;
}
// .el-dropdown-link {
//     cursor: pointer;
//     color: #409EFF;
//   }
//   .el-icon-arrow-down {
//     font-size: 12px;
//   }
.submenu>>>.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border-bottom: 0px solid transparent;
}
.el-submenu__title{
    padding: 0px;
}
.my-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin-left:20px;
}
.my-table td {
    border: 1px solid black;
}
table tr:nth-child(2n) {
    background: #fff;
}     
table tr:nth-child(2n+1) {
    background: #edf6ff;
}
.relaTable:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable:nth-child(2n) {
    background: #edf6ff;
    padding:5px;
}
.relaTable2:nth-child(2n+1) {
    background: #fff;
    padding:5px;
}
.relaTable2:nth-child(2n) {
    background: #e4e5e4;
    padding:5px;
}
</style>
