<template>
    <!-- <div class="mod-menu-home app-container" style="display:flex;flex-direction: column;align-items: center;"> -->
    <div class="curContainer">
        <!-- <div v-if="viewWindowId == 1" width="100%"> -->
            <div style="display:flex;align-items: center;justify-content: space-between;">
                <el-form inline :model="searchForm" label-width="80px" label-position="left">
                    <el-form-item label="创建日期">
                        <el-date-picker
                        v-model="searchForm.dateArray"
                        type="daterange"
                        range-separator="至"
                        value-format="yyyy-M-d"
                        start-placeholder="年/月/日"
                        end-placeholder="年/月/日">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="searchForm.key" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-button @click="onSearch" type="primary">搜索</el-button>
                </el-form>
                <div v-if="rightArr.canAdd" style="text-align:right;">
                    <el-button type="primary" @click="onProjectEdit(null)" style="margin-right:10px;">新建项目</el-button>
                </div>
            </div>
            <common-table  ref="tableObj" :data="projectList" border :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            style="width: 100%;margin-top: 10px;" stripe>
            <el-table-column prop="projectName" align="center" label="项目名称" >
                <template v-slot="scope">
                    <!-- <div style="color:#1989FA;cursor:pointer;" @click="onLookQues(scope.row)">{{scope.row.projectName}}</div>-->
                    <div >{{scope.row.projectName}}</div>    
                </template>
            </el-table-column>
            <el-table-column prop="sDate" width="100px" align="center" label="开始日期" >
                <template v-slot="scope">
                    {{scope.row.sDate.split('T')[0]}}                              
                </template>
            </el-table-column>
            <el-table-column prop="eDate" width="100px" align="center" label="截止日期" >
                <template v-slot="scope">
                    {{scope.row.eDate.split('T')[0]}}                              
                </template>
            </el-table-column>
            <el-table-column prop="num" width="95px" align="center" label="目标样本量" ></el-table-column>
            <el-table-column prop="eDate" align="center" label="配额" >
                <template v-slot="scope">
                    <div v-for="(item,index) in scope.row.qtList" :key="index">
                        <div style="display:flex;align-items: center;">
                            <div style="width:70%;    text-align: left;">{{item.title}}</div>
                            <div style="width:30%"> <el-progress style="margin:3px 0px" :text-inside="true" :stroke-width="26" :percentage="item.percent"></el-progress></div>
                        </div>
                    </div>
                    <!-- <el-progress style="margin:3px 0px" v-for="(item,index) in scope.row.qtList" :key="index" :text-inside="true" :stroke-width="26" :percentage="item.percent"></el-progress>                          -->
                </template>
            </el-table-column>
            <el-table-column prop="status" width="80px" align="center" label="状态" >
                <template v-slot="scope">
                    <span :style="scope.row.status === '下线'?'color:red;':'color:blue;'">{{ scope.row.status }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="roleId!=2" prop="name" width="80px" align="center" label="创建人" ></el-table-column>
            <el-table-column prop="rowTime" width="160px" align="center" label="创建时间" ></el-table-column>    
            <el-table-column v-if="rightArr.canModify" align="center" width="121px" label="操作" >
                <template v-slot="scope">   
                    <div style="line-height: 3;display:flex;">
                        <div class="btnGrp">
                            <el-tooltip class="item" effect="dark" content="设置问卷" placement="bottom">
                                <el-button type="primary" style="margin-left: 10px;" size="mini" icon="el-icon-setting" @click="onSetQuestion(scope.row)"></el-button>    
                            </el-tooltip>   
                            <el-tooltip class="item" effect="dark" content="系数设置" placement="top">
                                <el-button type="primary" size="mini" icon="el-icon-c-scale-to-original" @click="onSetRatio(scope.row)"></el-button>    
                            </el-tooltip>    
                            <el-tooltip class="item" effect="dark" content="渠道设置" placement="bottom">
                                <el-button type="primary" size="mini" icon="el-icon-share" @click="onSetAgent(scope.row)"></el-button>   
                            </el-tooltip>
                            <el-tooltip v-if="rightArr.canDelete" class="item" effect="dark" content="删除" placement="bottom">
                                <el-button type="danger" size="mini" icon="el-icon-delete" @click="onDeleProject(scope.row)"></el-button>   
                            </el-tooltip>
                        </div>
                        <div class="btnGrp">
                            <el-tooltip class="item" effect="dark" content="设置配额" placement="top">
                                <el-button type="primary" style="margin-left: 10px;" size="mini" icon="el-icon-data-line" @click="onSetQuota(scope.row)"></el-button>    
                            </el-tooltip>
                            <el-tooltip v-if="rightArr.canModify" class="item" effect="dark" content="编辑" placement="bottom">
                                <el-button type="primary" size="mini" icon="el-icon-edit" @click="onProjectEdit(scope.row)"></el-button>   
                            </el-tooltip> 
                            <el-tooltip v-if="rightArr.canUpdown" class="item" effect="dark" :content="scope.row.status === '下线'?'上线':'下线'" placement="top">
                                <el-button :type="scope.row.status === '下线'?'primary':'primary'" size="mini" :icon="scope.row.status === '下线'?'el-icon-top':'el-icon-bottom'" @click="onProjectSetStatus(scope.row)"></el-button>   
                            </el-tooltip>
                            
                            <el-tooltip class="item" effect="dark" content="清空调研结果" placement="bottom">
                                <el-button type="danger" size="mini" icon="el-icon-close" @click="onClearProjectAnswer(scope.row)"></el-button>   
                            </el-tooltip>
                        </div>

                        
                         
                        
                        
                        
                        <!-- <el-tooltip class="item" effect="dark" content="试答" placement="top">
                            <el-button type="success" size="mini" icon="el-icon-view" @click="onTry(scope.row)"></el-button>    
                        </el-tooltip>   -->
                        
                        <!-- <el-tooltip class="item" effect="dark" content="筛选媒体" placement="bottom">
                            <el-button type="primary" size="mini" icon="el-icon-remove" @click="onOpenFilterMedia(scope.row)"></el-button>   
                        </el-tooltip> -->
                        
                       
                       
                    </div>      
                </template>
            </el-table-column> 
            </common-table>

            <!-- <el-dialog title="项目编辑" v-model="editProjectVisible" width="80%"  
                                            :modal-append-to-body='false'
                                            :close-on-click-modal='false'
                                            v-if="editProjectVisible"
                                            append-to-body>
                <div  style="height:400px;">
                    <projectedit @close="onClose(para)" :project="curProject"/>
                </div>
            </el-dialog> -->
        <!-- </div> -->
        <el-dialog title="编辑项目" v-model="editProjectVisible" width="80%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="editProjectVisible"
                                append-to-body>
            <div>                    
                <projectedit @close="onProjectClose" :project="curProject"/>
                <!-- <eachquestion @close="onClose" /> -->
            </div>
        </el-dialog>
        <el-dialog title="设置问卷" v-model="editQuestionVisible" width="80%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="editQuestionVisible"
                                append-to-body>
            <div>
                <!-- 如果已有问题，则无法选择模板，否则，可以选择模板 -->
                <el-form inline :model="qaForm" label-position="left" label-width="80px">
                    <el-form-item label="选择模板">
                        <el-select v-model="qaForm.fromModelId" @change="onModelChange" placeholder="请选择">
                            <el-option
                            v-for="item in modelList"
                            :key="item.modelId"
                            :label="item.modelName"
                            :value="item.modelId"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <!-- <div v-if=""> -->
                    <all-questions style="margin-top:-30px;" is-model="false" @savemodel="onSaveQuestion" :ef="editForm"/>
                <!-- </div> -->
            </div>
        </el-dialog>
        <el-dialog title="设置配额" v-model="editQuotaVisible" width="60%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="editQuotaVisible"
                                class="dialog"
                                append-to-body>
            <div>                    
                <quota is-model="true" @savequota="onSaveQuota" :quota-list="projectQuotaList" :question-list="editForm.questions"/>
            </div>
        </el-dialog>
        <el-dialog title="设置系数" v-model="ratioVisible" width="60%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="ratioVisible"
                                class="dialog"
                                append-to-body>
            <div>                    
                <ratio ref="ratio" @saveratio="onSaveRatio" :can-query="curProject.canQuery" :ratio-list="projectRatioList" />
            </div>
        </el-dialog>

        <el-dialog title="筛选媒体" v-model="filterMediaVisible" width="60%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="filterMediaVisible"
                                class="dialog"
                                append-to-body>
            <div>                    
                
                <el-form inline :model="quickSetForm" label-width="140px" style="margin-top: 0px;">
                    <el-form-item label="选择问题">
                        <el-select v-model="quickSetForm.qId" style="width:400px;" clearable @change="onQuesChange" placeholder="请选择">
                            <el-option
                            v-for="item in questionList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <span style="margin-left:20px;color:red;">请选择媒体类问题</span>
                </el-form>
                <el-divider/>
                <div style="margin-left:20px;color:red;">请选择需要保留的内容，未选中的都将被删除</div>
                <el-table  ref="quesTable" :data="answers" border
                    :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                    :row-key="getRowKey"
                    @selection-change="selsChange"
                    style="width: 100%;margin-top: 10px;" stripe>
                    <el-table-column type="selection" reserve-selection="true" width="55"></el-table-column>
                    <el-table-column prop="name" align="center" label="名称" ></el-table-column>         
                    <!-- <el-table-column prop="name" align="center" label="名称" >
                        <template v-slot="scope">
                            <div style="color:#1989FA;cursor:pointer;" @click="onLookQues(scope.row)">{{scope.row.projectName}}</div>                                    
                        </template>
                    </el-table-column>                  -->           
                </el-table>
                <div style="text-align:center;margin-top:20px;">
                    <el-button @click="onSaveQuickSetMedia" type="primary">保存</el-button>
                    <el-button @click="filterMediaVisible = false">关闭</el-button>
                </div>
            </div>
        </el-dialog>

        <el-dialog title="渠道设置" v-model="agentVisible" width="60%"  
                                :modal-append-to-body='false'
                                :close-on-click-modal='false'
                                v-if="agentVisible"
                                class="dialog"
                                append-to-body>
            <div>        
                <!-- asdads             -->
                <agent @saveagent="onSaveAgent" :share-list="shareList" :project-item="curProject"/>
            </div>
        </el-dialog>

        
        <!-- <div v-if="viewWindowId == 2" width="100%">
            <projectedit @close="onClose(para)" :project="curProject"/>
        </div> 
        <div v-if="viewWindowId == 3" width="100%">
            <all-questions is-model="true" @savemodel="onSave" :ef="this.editForm"/>
        </div>
        <div v-if="viewWindowId == 4" width="100%">
            
        </div>-->
    </div>
    <!-- </div> -->

</template>
<script>
import { mapGetters } from "vuex"; 
import {getLatestMonthDateArrayByDate} from "@/utils/time";
import CommonTable from "@/components/CommonTable";
import projectedit from '@/views/Survey/bkground/projectEdit';
import { DeleProject, SaveModel, SaveProjectQuota, SetProjectStatus, QuickSelectMedia, SaveProjectRatio,SaveAgent,ClearProjectAnswer } from "@/api/survey";
import {getToken,getRoleToken} from "@/utils/auth";
import allQuestions from '@/views/Survey/bkground/component/allQuestions';
import quota from '@/views/Survey/bkground/component/quota';
import ratio from '@/views/Survey/bkground/component/ratio';
import agent from '@/views/Survey/bkground/component/agent';
import Vue from 'vue'
import { encrypt } from '@/utils/encrypt'
// import eachquestion from '@/views/Survey/bkground/component/eachQuestion';
import {UserRoleModuleDetail} from "@/api/user";

export default {
    components: {
        CommonTable,projectedit,allQuestions,quota,ratio,agent
    },
    computed: {...mapGetters(['projectList','projectQADetail','modelList','modelDetail','projectQuotaList','questionList'
                ,'answerList','colList','projectRatioList','projectAgentList'])},
    watch:{
        'qaForm.fromModelId':{
            handler(val, oldVal) {
                // console.log('val, oldVal',val, oldVal)
                this.qaForm.preFromModelId = oldVal
            }
        }
    },
    data() {
        return {
            searchForm: {
                dateArray: getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),
                key: ''
            },
            qaForm: { fromModelId: '',preFromModelId: '' },
            editForm: {},
            editProjectVisible: false,
            editQuestionVisible: false,
            editQuotaVisible: false,
            curProject: {} ,
            roleId: 2,
            filterMediaVisible: false,
            ratioVisible:false,
            quickSetForm: {
                qId: ''
            },
            answers: [],
            haveSelectList: [],
            shareList:[{id:'',name:'',url:''}],
            agentVisible:false,
            // fromModelId: '',
            // preFromModelId: '' // 记录改变选择前的modelId
            // viewWindowId: 1
            rightArr:{},
        };
    },
    async mounted() {
        if (!localStorage.getItem('loginInfo')){
            return
        }
        var params = {userId:getToken(),moduleId:1}
		console.log('3a')
        await UserRoleModuleDetail(params).then((res)=>{
            // console.log('data',res)
            this.rightArr = res.data[0]
        })
        
        this.roleId = JSON.parse(localStorage.getItem('loginInfo')).roleId
        await this.$store.dispatch('survey/GetModelList',{userId: getToken(), sDate:'2023-1-1', eDate:'2099-12-31', key: '' }).then(()=>{ })
        await this.onSearch();     
        this.$nextTick(() => {                
            this.clearSelect();
        })
    },
    methods: {
        async onSearch(){
            const {dateArray: [sYM, eYM],key} = this.searchForm;
            var sDate = new Date(sYM).toLocaleDateString();
            var eDate = new Date(eYM).toLocaleDateString();
            await this.$store.dispatch('survey/GetProjectList',{userId: getToken(), sDate, eDate, key: key }).then(()=>{ })
        },
        onProjectEdit(item){
            this.editProjectVisible = true
            if (item == null) {
                this.curProject = {projectId: '', projectName: '', dateArray: [], num: '', brandName: ''}
            } else {
                this.curProject = item
            }
        },
        onProjectSetStatus(item){
            var toSet = '上线'
            if (item.status === '上线') {
                toSet = '下线'
            }
            this.$confirm('确认'+toSet+'"'+item.projectName+'", 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var obj = {userId: getToken(), projectId: item.projectId, statusId: 0}      
                await SetProjectStatus(obj).then((res)=>{
                    // console.log('res',res)
                    // this.onSearch()
                    item.status = toSet
                })
            }).catch(() => {
                     
            });
        },
        // 查看问卷
        onLookQues(item){
            window.open(item.path + 'survey/' + encrypt(item.projectId) +'/'+item.rxsign+ '/0/0/0');
            // console.log('a'+item.projectId+'b',encrypt(item.projectId))
            // window.open('http://localhost:8080/#/survey/' + encrypt(item.projectId) + '/0');
        },
        async onSetAgent(item){
            this.curProject = item
            await this.$store.dispatch('survey/GetProjectAgentList',{userId: getToken(), projectId:item.projectId }).then(()=> {
                this.shareList = this.projectAgentList
                this.agentVisible=true
            })            
        },
        onTry(item){
            window.open(item.path + 'survey/' + encrypt(item.projectId) +'/tset'+item.rxsign+ '/0/0/0');
        },
        // 设置问卷
        async onSetQuestion(item){
            this.curProject = item
            this.qaForm.fromModelId = ''
            await this.$store.dispatch('survey/GetProjectQADetail',{userId: getToken(), projectId:item.projectId }).then(()=>{ 
                this.editQuestionVisible = true
                this.editForm = this.projectQADetail
                // console.log('this.editForm',this.editForm)
            })            
            // this.viewWindowId = 3
        },
        // 选择的模板发生变化时
        async onModelChange(){
            // console.log('zenmehuishi')
            var that = this
            if (this.editForm.questions.length > 0){
                this.$confirm('当前项目已设置问题，如果重新选择模板，则当前所有已设置的问题将被替换, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch('survey/GetModelDetail',{userId: getToken(), modelId:this.qaForm.fromModelId }).then(()=>{ 
                        this.editForm = JSON.parse(JSON.stringify(this.modelDetail))
                        // console.log('fff', this.curProject.brandName)
                        this.editForm.questions.forEach(ele1 => {
                            ele1.name = ele1.name.replace('XXX',this.curProject.brandName)
                            ele1.answers.forEach(ele2 => {
                                ele2.answer = ele2.answer.replace('XXX',this.curProject.brandName)
                            });
                            
                            ele1.queCols.forEach(ele2 => {
                                ele2.colName = ele2.colName.replace('XXX',this.curProject.brandName)
                            });
                            ele1.relation.forEach(ele2 => {
                                ele2.relationA.forEach(ele3 => {
                                    ele3.answer = ele3.answer.replace('XXX',this.curProject.brandName)
                                })
                                ele2.relationC.forEach(ele3 => {
                                    ele3.colName = ele3.colName.replace('XXX',this.curProject.brandName)
                                })
                            });
                        });
                    })
                }).catch(() => {
                    // console.log('aaaa',that.qaForm.preFromModelId)
                    that.qaForm.fromModelId = that.qaForm.preFromModelId
                });
            } else {
                await this.$store.dispatch('survey/GetModelDetail',{userId: getToken(), modelId:this.qaForm.fromModelId }).then(()=>{ 
                    this.editForm = JSON.parse(JSON.stringify(this.modelDetail))
                    this.editForm.questions.forEach(ele1 => {
                            ele1.name = ele1.name.replace('XXX',this.curProject.brandName)
                            ele1.answers.forEach(ele2 => {
                                ele2.answer = ele2.answer.replace('XXX',this.curProject.brandName)
                            });
                            
                            ele1.queCols.forEach(ele2 => {
                                ele2.colName = ele2.colName.replace('XXX',this.curProject.brandName)
                            });
                            ele1.relation.forEach(ele2 => {
                                ele2.relationA.forEach(ele3 => {
                                    ele3.answer = ele3.answer.replace('XXX',this.curProject.brandName)
                                })
                                ele2.relationC.forEach(ele3 => {
                                    ele3.colName = ele3.colName.replace('XXX',this.curProject.brandName)
                                })
                            });
                        });
                })
            }
            
        },
        // 保存问卷内容
        async onSaveQuestion(obj){
            if (obj == null){
                this.editQuestionVisible = false 
            } else {
                // if (obj.questions.length === 0) {
                //     this.$message.error("存在关联关系，无法删除，可先移除关联关系");
                // }
                console.log('this.curProject.projectId',this.curProject)
                obj.isModel = false
                obj.modelId = this.curProject.projectId// this.qaForm.fromModelId//
                obj.userId = getToken()

                this.$confirm('确认修改项目，修改后原设置的配额将无效，同时现有用户已做的回答也将无效, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await SaveModel(obj).then((res)=>{
                        this.onSearch()
                        // localStorage.removeItem('curModel') 
                        this.editQuestionVisible = false 
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });          
                });


                
            }
        },
        // 设置配额
        async onSetQuota(item){
            this.curProject = item            
            await this.$store.dispatch('survey/GetProjectQADetail',{userId: getToken(), projectId:item.projectId }).then(async ()=>{ 
                await this.$store.dispatch('survey/GetProjectQuotaList',{userId: getToken(), projectId:item.projectId }).then(()=>{ 
                    this.editForm = this.projectQADetail
                    // console.log('aaa',this.editForm)
                    this.editQuotaVisible = true                    
                })
                
                // console.log('this.editForm',this.editForm)
            })         

            
        },
        async onSetRatio(item){
            this.curProject = item            
            await this.$store.dispatch('survey/GetProjectRatio',{userId: getToken(), projectId:item.projectId }).then(()=>{
                    this.ratioVisible = true
                    console.log(this.$refs.ratio)
                    this.$nextTick(() => {
                        this.$refs.ratio.init()
                    })
                }
            )
        },
        async onSaveRatio(obj){
            if (obj == null){
                this.ratioVisible = false 
            } else {
                var newObj = {}
                console.log('obj',obj)
                // obj.answerItem = obj.answerItemArray.join('^')
                obj.forEach(ele => {
                    if (ele.answerItemArray.length>0){
                        ele.answerItem = ele.answerItemArray.join('^')
                    }else{
                        ele.answerItem = ''
                    }
                });
                newObj.projectRatio = obj
                newObj.projectId = this.curProject.projectId
                newObj.userId = getToken()
                // console.log('aaa',newObj)
                await SaveProjectRatio(newObj).then((res)=>{
                    this.ratioVisible = false 
                })
            }
        },
        async onSaveQuota(obj){
            if (obj == null){
                this.editQuotaVisible = false 
            } else {
                var newObj = {}
                newObj.quotas = obj
                newObj.projectId = this.curProject.projectId
                newObj.userId = getToken()
                await SaveProjectQuota(newObj).then((res)=>{
                    this.onSearch()
                    // localStorage.removeItem('curModel') 
                    this.editQuotaVisible = false 
                })
            }
            
        },
        async onSaveAgent(obj){
            console.log('obj',obj)
            if (obj == null){
                this.agentVisible = false 
            } else {
                var newObj = {}
                newObj.projectAgent = obj
                newObj.projectId = this.curProject.projectId
                newObj.userId = getToken()
                await SaveAgent(newObj).then((res)=>{
                    this.onSearch()
                    // localStorage.removeItem('curModel') 
                    this.agentVisible = false 
                })
            }
        },
        async onDeleProject(item){      
            this.$confirm('确认删除'+item.projectName+', 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var obj = {projectId:item.projectId,userId:getToken()}      
                await DeleProject(obj).then((res)=>{
                    console.log('res',res)
                    this.onSearch()
                })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
            
        },

        async onClearProjectAnswer(item){
            this.$confirm('确认清空《'+item.projectName+'》回收的调研结果，清除后无法恢复, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var obj = {projectId:item.projectId,userId:getToken()}      
                await ClearProjectAnswer(obj).then((res)=>{
                    console.log('res',res)
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                        });
                    // this.onSearch()
                })
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '已取消删除'
                // });          
            });
        },
        // 打开快捷移除媒体窗口
        async onOpenFilterMedia(item){
            this.curProject = item
            this.answers = []
            await this.$store.dispatch('survey/GetQuestionList',{userId: getToken(), projectId:item.projectId }).then(()=>{
                this.filterMediaVisible = true
             })            
        },
        async onQuesChange(){
            await this.$store.dispatch('survey/GetAnswerList',{userId: getToken(), questionId:this.quickSetForm.qId }).then(()=>{
                this.$nextTick(() => {                
                    this.clearSelect();
                })
                this.haveSelectList=[];
                this.answers = this.answerList
             })              
        },
        getRowKey(row){
            return row.id;
        },
        selsChange(val){
            this.haveSelectList = val;
        },
        clearSelect(){
            // console.log('aaaa',this.$refs.quesTable)
            // for (let i = 0; i < this.answers.length; i++) {
            //     this.$refs.quesTable.toggleRowSelection(this.answers[i],false)
            // }
            this.$refs.quesTable.clearSelection()
        },
        async onSaveQuickSetMedia(){
            // console.log(this.haveSelectList)
            if (this.haveSelectList && this.haveSelectList.length>0){
                this.$confirm('确认只保留当前选中的'+this.haveSelectList.length+'项，其他项删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    var selAIds=''
                    this.haveSelectList.forEach(ele => {
                        if (selAIds == ''){
                            selAIds = ele.id
                        } else {
                            selAIds = selAIds + ',' + ele.id
                        }
                    });
                    var obj = {userId: getToken(),projectId: this.curProject.projectId,selAIds}
                    // console.log(obj)
                    await  QuickSelectMedia(obj).then((res)=>{
                        this.onSearch()
                        this.filterMediaVisible = false 
                    })
                }).catch(() => {
                           
                });

                
            }else{
                this.$message.warning("请选择需要保留的内容");
                return;
            }
        },
        onProjectClose(fresh){
            // console.log('fresh',fresh)
            this.editProjectVisible=false
            if (fresh){
                this.onSearch();     
            }            
        }
    }
}
</script>
 
<style scoped lang="scss">
// .dialog >>> .el-dialog__body{
//     min-height: 200px;
// }
// .el-dialog{
//     .el-dialog__body{
//         height: 2000px;
//     }
// }
.btnGrp{
  text-align:  center;
  width:50%;
}
.dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 9999;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
</style>
