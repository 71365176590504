<template>
    <div >
         <el-form inline :model="form" label-width="180px" style="margin-top: 30px;">
            <el-form-item label="项目名称">
                <el-input v-model="form.projectName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="起止日期">
                <!-- <el-date-picker
                v-model="form.dateArray"
                type="daterange"
                range-separator="至"
                value-format="yyyy-M-d"
                start-placeholder="年/月/日"
                end-placeholder="年/月/日">
                </el-date-picker> -->
                <el-date-picker
                    v-model="form.dateArray"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-M-d"
                    start-placeholder="年/月/日"
                    end-placeholder="年/月/日">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="调研品牌">
                <el-input v-model="form.brandName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="目标样本量">
                <el-input v-model="form.num" autocomplete="off"></el-input>
            </el-form-item>
            <el-divider content-position="left">最大媒体数量(0为无限制)</el-divider>
            <el-row>
                <el-form-item label="媒体">
                    <!-- <el-input v-model="form.maxMedia" autocomplete="off"></el-input> -->
                    <el-input-number v-model="form.maxMedia_MT" :min="0" :max="100" ></el-input-number>
                </el-form-item>
                <el-form-item label="营销">
                    <el-input-number v-model="form.maxMedia_YX" :min="0" :max="100" ></el-input-number>
                </el-form-item>
                <!-- <el-form-item label="每页显示一题">
                    <el-switch
                        v-model="form.oneQuestionPerPage"
                        active-text="是"
                        inactive-text="否">
                    </el-switch>
                </el-form-item> -->
            </el-row>
            <el-row>
                <el-form-item label="内容类型">
                    <el-input-number v-model="form.maxMedia_NRLX" :min="0" :max="100" ></el-input-number>
                </el-form-item>
                <el-form-item label="内容风格">
                    <el-input-number v-model="form.maxMedia_NRFG" :min="0" :max="100" ></el-input-number>
                </el-form-item>
            </el-row>
        </el-form>
        <div style="text-align:center;margin-top:20px;">
            <el-button @click="onSave" type="primary">保存</el-button>
            <el-button @click="close()">关闭</el-button>
        </div>
           
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import {SaveProject} from "@/api/survey";
  import {getToken} from "@/utils/auth";
  import {getNextMonthDateArray ,getLatestMonthDateArrayByDate, getCurrentDate} from "@/utils/time";

  export default {
      props:['project'],
      components: {
      },
      data() {
          return {
             form:{
                projectId: '',
                projectName: '',
                dateArray: [],//getLatestMonthDateArrayByDate({date:new Date(),delayNum:1, formatValue: 'yyyy-MM-dd'}),//['2023/05/26','2023/06/26'],// getNextMonthDateArray(),// getMonthDateArrayByPrePostSafari2({date:new Date(),preDelayNum:0,postDelayNum:1, formatValue: 'yyyy-MM-dd'}),
                num: '',
                brandName: '',
                oneQuestionPerPage:true,
                maxMedia:0,
                maxMedia_MT:0,
                maxMedia_YX:0,
                maxMedia_NRFG:0,
                maxMedia_NRLX:0,
             }
          };
      },
  
      computed: {
          ...mapGetters([])
      },
  
      async mounted() {
        this.form = this.project;   
        this.form.dateArray = [this.form.sDate,this.form.eDate]
        // console.log('this.form',this.form) 
      },
  
      methods:{
        async onSave(){
            const {projectId,projectName,dateArray: [sYM, eYM],num,brandName,oneQuestionPerPage,maxMedia,maxMedia_MT,maxMedia_YX,maxMedia_NRFG,maxMedia_NRLX} = this.form;
            // console.log('aaa',this.form)
            // return
            var sDate, eDate
            if (sYM){
                sDate = new Date(sYM).toLocaleDateString();
                eDate = new Date(eYM).toLocaleDateString();
            } else {
                sDate = new Date(getCurrentDate()).toLocaleDateString();
                eDate = sDate;
            }
            const obj = { projectId, projectName, sDate, eDate, num, brandName,userId: getToken(),oneQuestionPerPage,maxMedia,maxMedia_MT,maxMedia_YX,maxMedia_NRFG,maxMedia_NRLX }
            console.log(obj)
            await SaveProject(obj).then((res)=>{
                // console.log('res',res)
            })
            this.$emit('close', true)
        },
        close() {
            this.$emit('close', false);
        }
      }
  
  }
  </script>
  
  <style>
    *{
        margin: 0;
        padding: 0;
    }
    .main{
        width: 96%;
        /* height: 800px; */
        position: absolute;
    }
    .quarter-div{
        width: 49%;
        /* height: 48%; */
        float: left;
    }

    .el-carousel__container{
        height:480px;
        /* height: 100% !important; */
    }
  </style>